validation-provider
<template>
  <auth-layout>
    <!-- Register-->

    <b-col
      lg="5"
      class="d-flex align-items-center auth-bg px-2 pt-lg-3 justify-content-center"
      style="max-height: 100vh"
    >
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="ps-customizer-area scroll-area"
      >
        <b-col sm="8" md="6" lg="12" class="pb-lg-3 mx-auto">
          <b-card-title class="mb-1 text-center">
            Adventure starts here
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            Make your app management easy and fun!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- first name -->
              <b-form-group label-for="firstName" label="First Name">
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  vid="firstName"
                  rules="required"
                >
                  <b-form-input
                    id="firstName"
                    v-model="firstName"
                    name="firstName"
                    placeholder="John"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- last name -->
              <b-form-group label-for="lastName" label="Last Name">
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  vid="lastName"
                  rules="required"
                >
                  <b-form-input
                    id="lastName"
                    v-model="lastName"
                    name="lastName"
                    placeholder="Doe"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    name="email"
                    placeholder="john@example.com"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- phone number -->

              <b-form-group label="Phone Number" label-for="phone">
                <b-input-group>
                  <v-select
                    v-model="country"
                    :options="countries"
                    label="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="(country) => country.value"
                    class="col-4 col-g-0"
                  />
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    vid="phone"
                    rules="required|integer"
                    class="col-8 col-g-0"
                  >
                    <b-form-input
                      id="phone"
                      type="tel"
                      v-model="phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="729542528"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="invalidPhone">Invalid Phone Number</small>
                  </validation-provider>
                </b-input-group>
              </b-form-group>

              <!-- Website -->
              <b-form-group label="Website" label-for="website">
                <b-form-input id="website" v-model="website" name="website" placeholder="https://www.john.com" />
              </b-form-group>

              <!-- password -->
              <b-form-group label="Password" label-for="password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="cPassword"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="cPassword"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="cPassword"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Individual or An Organization" class="mb-2">
                <b-row no-gutters>
                  <b-form-radio
                    v-model="isCompany"
                    name="company"
                    :value="false"
                    class="col"
                  >
                    Individual
                  </b-form-radio>

                  <b-form-radio
                    v-model="isCompany"
                    name="company"
                    :value="true"
                    class="col"
                  >
                    Organization
                  </b-form-radio>
                </b-row>
              </b-form-group>

              <b-form-group class="mb-2">
                <b-form-checkbox
                  id="status"
                  v-model="status"
                  name="status"
                  :value="true"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
                <small class="text-danger" v-if="!status && status != 'start'"
                  >The Policy field is required</small
                >
              </b-form-group>

              <b-button
                variant="primary"
                block
                class="d-flex align-items-center justify-content-center"
                type="submit"
                :disabled="invalid || invalidPhone || (!status || status == 'start') || formIsLoading"
                :class="{ disabled: invalid }"
              >
                <b-spinner small v-if="formIsLoading" />
                &nbsp;
                Get Started
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>
        </b-col>
      </vue-perfect-scrollbar>
    </b-col>

    <!-- /Register-->
  </auth-layout>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AuthLayout from "@/layouts/auth/AuthLayout.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormRadio,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BSpinner,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { required, email, integer } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    AuthLayout,
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BSpinner,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BFormInput,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    VuePerfectScrollbar,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      cPassword: "",
      phone: "",
      website: "",
      isCompany: false,
      country: null,
      status: "start",
      // validation
      required,
      email,
      integer,
      countries: [],

      formIsLoading:false,
      
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    invalidPhone(){
      if(this.phone == "" || this.phone == null){
        return false;
      }
      let phoneSanitize = this.phone
      phoneSanitize = phoneSanitize.replaceAll(" ", '')
      if(phoneSanitize.substr(0, 1) == "+"){
        phoneSanitize = phoneSanitize.substr(1)
      }
      if(phoneSanitize.substr(0, 2) == "00"){
        phoneSanitize = phoneSanitize.substr(2)
      }
      if(phoneSanitize.substr(0, 1) == "0"){
        phoneSanitize = phoneSanitize.substr(1)
      }
      if(phoneSanitize.substr(0, 3) == "254"){
        phoneSanitize = phoneSanitize.substr(3)
      }

      if (phoneSanitize.length == 9) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    register() {
      this.formIsLoading = true;
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$http.post('/register',{
              first_name:this.firstName,
              last_name:this.lastName,
              email:this.email,
              password:this.password,
              phone:this.phone,
              website:this.website,
              is_company:this.isCompany,
              country_id:this.country,
            })
            .then(response => {
              this.formIsLoading = false;
              this.$router
                .push({ 
                  name: 'verify-account-codes',
                  params: { id: response.data.data.ID }
                })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `User ${ response.data.data.first_name + " " + response.data.data.last_name } Registered`,
                      icon: "CheckIcon",
                      variant: "success",
                      text: 'You have successfully registered your account, kindly verify your phone number and email address.',
                    },
                  });
                });
            })
            .catch(error => {
               for (let err of error.response.data.error) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title:err,
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                }
                this.formIsLoading = false;
            })
        }
      })
    },
  },
  created(){
    this.$http.get('/countries')
    .then(response => {
      for (const country of response.data.data) {
        let pushCountry = {
          value : country.ID,
          text: country.iso + " (" + country.code + ")",
        }
        this.countries.push(pushCountry);
        if (country.title = "Kenya"){
          this.country = country.ID;
        }
      }
    })
    .catch(error => {
      for (let err of error.response.data.error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title:err,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
    })
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select {
  padding-left: 0px !important;
  .vs__actions {
    display: none !important;
  }
}
.col-g-0 {
  padding-right: 0;
}

.ps-customizer-area {
  height: 100%;
  width: 100%;
}
</style>
